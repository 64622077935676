import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import { add, differenceInCalendarDays, formatISO, isValid } from "date-fns";
import { StudyContactRole } from "app/shared/constants";

const iteratee = (baseObj) => (result, value, key) => {
  if (!isEqual(value, baseObj[key])) {
    let tmpObj;
    // check if this is a Date object, if yes, only compare Date part, not time
    if (value instanceof Date && baseObj[key] instanceof Date) {
      tmpObj = isEqual(
        value.toLocaleDateString(),
        baseObj[key].toLocaleDateString()
      )
        ? null
        : value;
      // check if this is Date in String format, if yes, only compare Date part, not time
    } else if (
      typeof value === "string" &&
      typeof baseObj[key] === "string" &&
      Date.parse(value) &&
      Date.parse(baseObj[key])
    ) {
      tmpObj = isEqual(
        new Date(value).toLocaleDateString(),
        new Date(baseObj[key]).toLocaleDateString()
      )
        ? {}
        : value;
    } else if (typeof baseObj[key] !== "undefined" || !isEmpty(value)) {
      // if server returned object don't have the property and UI set as null/"", ignore it
      const valIsObj = isObject(value) && isObject(baseObj[key]);
      tmpObj =
        valIsObj === true ? differenceObject(value, baseObj[key]) : value;
    }
    // filter out empty object
    if (tmpObj && tmpObj instanceof Date) {
      result[key] = tmpObj;
    } else if (isObject(tmpObj)) {
      if (!isEmpty(tmpObj)) {
        result[key] = tmpObj;
      }
    } else if (typeof tmpObj !== "undefined") {
      result[key] = tmpObj;
    }
  }
};

export const isValidUser = (user) => {
  return user != null && user.permissions != null && user.permissions.ALL;
};

// diff but array will have empty element
export const differenceObject = (targetObj, baseObj) => {
  return transform(targetObj, iteratee(baseObj), null);
};

const findLeadCRC = (contacts) => {
  if (contacts != null && contacts.length > 0) {
    return contacts.find((contact) => {
      return contact.leadCrc === true;
    });
  } else {
    return null;
  }
};

const findPrincipalInvestigartor = (contacts) => {
  if (contacts != null && contacts.length > 0) {
    return contacts.find((contact) => {
      return contact.role === StudyContactRole.PI;
    });
  } else {
    return null;
  }
};

export const getDisplayPercentage = (number) => {
  //return Number(number).toFixed(2);
  // the above commented code is flooring and ceiling the number
  // so changed it with trunc.
  return Math.trunc(number * 100) / 100;
};

const getRecruitedPercentage = (study) => {
  if (study.protocolTargetAccrual > 0 && study.subjectSummary) {
    return getDisplayPercentage(
      (study.subjectSummary.enrolled * 100) / study.protocolTargetAccrual
    );
  } else {
    return getDisplayPercentage(0);
  }
};

const getAvailableProspectPercentage = (study) => {
  if (study.prospectSummary) {
    return getDisplayPercentage(
      study.prospectSummary.total > 0
        ? (study.prospectSummary.outreachable * 100) /
            study.prospectSummary.total
        : 0
    );
  }
};

const getDaysElapsed = (study) => {
  if (study.recruitmentStartDate != null) {
    const startDate = new Date(study.recruitmentStartDate);
    const today = new Date();
    return differenceInCalendarDays(today, startDate);
  } else {
    return 0;
  }
};

const getTotalDays = (study) => {
  if (study.recruitmentStartDate != null && study.recruitmentEndDate != null) {
    const startDate = new Date(study.recruitmentStartDate);
    const endDate = new Date(study.recruitmentEndDate);
    return differenceInCalendarDays(endDate, startDate);
  } else {
    return 0;
  }
};

export const mapStudyToDisplayed = (study) => {
  const leadCRC = findLeadCRC(study.studyContacts);
  const pi = findPrincipalInvestigartor(study.studyContacts);
  const recruited = getRecruitedPercentage(study);
  const availableProspect = getAvailableProspectPercentage(study);
  const daysElapsed = getDaysElapsed(study);
  const totalDays = getTotalDays(study);
  const timeElapsed = getDisplayPercentage(
    totalDays > 0 ? (daysElapsed * 100) / totalDays : 0
  );

  const newStudy = {
    ...study,
    ...{
      // make a copy of leadCRC so it won't change leadCrc,
      // we don't want this value to be sent to BackEnd during EDIT
      // this is only used for populating study listing column
      leadCrc: { ...leadCRC },
      leadCrcName: leadCRC ? leadCRC.firstName + " " + leadCRC.lastName : "",
      pi: pi,
      piName: pi ? pi.firstName + " " + pi.lastName : "",
      riskCount: study.riskConditions ? study.riskConditions.length : 0,
      availableProspect: availableProspect + "%",
      notOutreached: study.prospectSummary
        ? study.prospectSummary.outreachable
        : 0,
      totalProspect: study.prospectSummary ? study.prospectSummary.total : 0,
      recruited: recruited + "%",
      prospectOnStudy: study.subjectSummary ? study.subjectSummary.enrolled : 0,
      timeElapsed: timeElapsed + "%",
      recruitmentDays: daysElapsed,
      recruitmentTotalDays: totalDays,
      protocolIrbExpirationDate: convertDateStringToLocalDatetime(
        study.protocolIrbExpirationDate
      ),
      followUpDate: convertDateStringToLocalDatetime(study.followUpDate),
      recruitmentStartDate: convertDateStringToLocalDatetime(
        study.recruitmentStartDate
      ),
      recruitmentEndDate: convertDateStringToLocalDatetime(
        study.recruitmentEndDate
      ),
    },
  };

  return newStudy;
};

export const mapStudiesToDisplayed = (data) => {
  return data.map((study) => mapStudyToDisplayed(study));
};

export const mapOutreachStatsToDisplayed = (stats) => {
  if (stats == null || stats.hourBest == null || stats.hourWorst == null)
    return;

  const newData = {
    ...stats,
    ...{
      hourBest: {
        value:
          stats.hourBest.value < 12
            ? stats.hourBest.value + "AM"
            : stats.hourBest.value - 12 + "PM",
        percentage: stats.hourBest.percentage,
      },
      hourWorst: {
        value:
          stats.hourWorst.value < 12
            ? stats.hourWorst.value + "AM"
            : stats.hourWorst.value - 12 + "PM",
        percentage: stats.hourWorst.percentage,
      },
    },
  };
  return newData;
};

export const removeTzFromLocalDatetime = (dateTime) => {
  if (dateTime) {
    const result = formatISO(dateTime, { representation: "date" });
    return result;
  }
  return null;
};

export const convertDateStringToLocalDatetime = (dateStr) => {
  if (dateStr) {
    let newDate = new Date(dateStr);
    // timezone offset is in minutes, can be positive or negative
    return add(newDate, { minutes: newDate.getTimezoneOffset() });
  }
  return null;
};

export const countOutreachTotal = (outreachHistory) => {
  const newTotal = {
    quantity: 0,
    pepOnStudy: 0,
  };

  if (outreachHistory != null && outreachHistory.length > 0) {
    newTotal.quantity = outreachHistory.reduce(function (result, item) {
      return result + item.quantity;
    }, 0);
    newTotal.pepOnStudy = outreachHistory.reduce(function (result, item) {
      return result + item.subjectSummary.pepEnrolled;
    }, 0);
  }
  return newTotal;
};

export const addReverseIndexToElements = (
  xs,
  itemsPerPage,
  currentPage,
  totalCount
) => {
  return xs.map((x, index) => {
    x.index = totalCount - (currentPage * itemsPerPage + index + 1) + 1;
    return x;
  });
};

export const addIndexToElements = (history, itemsPerPage, currentPage) => {
  return history.map((x, index) => {
    x.index = (currentPage * itemsPerPage) + index + 1;
    return x;
  });
}

export const getPrefByName = (initData, name) => {
  if (
    initData.recruitmentPreferences &&
    initData.recruitmentPreferences.length > 0
  ) {
    return initData.recruitmentPreferences.find((pref) => {
      return pref.name === name;
    });
  } else {
    return null;
  }
};

export const isStudiesLocation = (pathname) => {
  if (pathname === "/" || pathname.includes("studies")) {
    return true;
  }
  return false;
};

export const setDefaultHB = (honestBrokers, currentUser) => {
  if (honestBrokers && honestBrokers.length > 0) {
    const result = honestBrokers.find(
      (contact) => contact.userName === currentUser.uid
    );
    return result ? result.id : "";
  }
  return "";
};

export const encodeBase64 = (str) => {
  try {
    return window.btoa(unescape(encodeURIComponent(str)));
  } catch (err) {
    return;
  }
};

export const decodeBase64 = (str) => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch (err) {
    return null;
  }
};

//Supported date format dd/mm/yyyy, mm/dd/yyyy, yyyy/mm/dd, yyyy/dd/mm
export const maskDOB = (date) => {
  //Condition to validate date
  if (isValid(new Date(date))) {
    //regex to identify year of birth in DOB
    var regexVar = /(\d{4})/g;
    //Replacing the year of birth in DOB
    return date.replace(regexVar, "XXXX");
  }
};

//Email Address Masking
const maskEmailString = (str) => {
  var strLen = str.length;
  if (strLen > 4) {
    return str.substr(0, 1) + str.substr(1, strLen).replace(/\w/g, "*");
  }
  return str.replace(/\w/g, "*");
};
export const maskEmailAddress = (emailAddress) => {
  const maskedEmailAddress = emailAddress.replace(
    /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
    function (mailAddress, username, mailServer, domain) {
      return `${username}@${maskEmailString(mailServer)}${domain}`;
    }
  );

  return maskedEmailAddress;
};

//Phone number masking. Supported formats (XXX) XXX-XXXX, XXX-XXX-XXXX, +1-XXX-XXX-XXXX.
export const maskPhoneNumber = (phone) => {
  var regexVar = /(\d{4})/g;
  //Replace the latest four digit number with 'X'
  return phone.replace(regexVar, "XXXX");
};

export const handleTableauUrls = () => {
  if (window.DEPLOY_ENV === "dev") {
    return `${process.env.REACT_APP_TABLEAU_DEV_URL}`;
  }
  return `${process.env.REACT_APP_TABLEAU_PROD_URL}`;
};

export const removeEmptyParams = (params) => {
  for (const key of Object.keys(params)) {
    if (params[key] === null) {
      delete params[key];
    }
  }
};

export const roundTo = (number, precision) => {
  if (precision > 6) throw new Error("Precision of at most 6 is allowed");
  const pows = [1, 10, 100, 1000, 10000, 100000, 1000000];
  if (typeof number === "number" && !isNaN(number) && isFinite(number))
    return Math.round(number * pows[precision]) / pows[precision];
  return 0;
};

export const renderContentBySplitingOnFullStop = (content) => {
  return content.split(".").map((reason, index, array) => (
    <>
      <span key={reason.trim()}>
        {reason.trim()}
        {index < array.length - 1 && "."}{" "}
      </span>
      <br />
    </>
  ));
};
